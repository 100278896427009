import { Box, Checkbox, FormControlLabel, TableCell } from "@mui/material";

import React, { memo, useMemo, useState } from "react";
import { withRouter } from "react-router";
import Botones from "./Botones";
import { butonIconTransition, useRowStyles } from "../styles/stylesRows";
import {
  ExpandLess,
  ExpandMore,
  MenuOpen,
  ReadMore,
} from "@mui/icons-material";

const ButtonsAction = (props) => {
  const {
    arrayExport,
    setarrayExport,
    campoExport,
    row,
    botones,
    claseCelda,
    agregarCelda,
    agrupado,
    rowPadre,
  } = props;
  const classes = useRowStyles();
  const [expanded, setExpanded] = useState(false);
  const checkButtons = useMemo(
    () =>
      botones.filter(
        (boton) =>
          boton.tipo === "check" ||
          boton.Icon === ExpandLess ||
          boton.Icon === ExpandMore
      ),
    [botones]
  );

  const allBotones = useMemo(() => botones.filter((boton) => !boton.ocultar), [
    botones,
  ]);

  return (
    <>
      {campoExport ? (
        agrupado ? (
          <TableCell
            size="small"
            padding="checkbox"
            style={{
              borderRight: "2px solid #ccc",
              maxWidth: "1rem",
            }}
            className={claseCelda ? claseCelda : classes.celda}
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              <FormControlLabel
                sx={{
                  margin: {
                    margin: "0px",
                    padding: "2px",
                  },
                }}
                control={
                  <Checkbox
                    size="small"
                    sx={{
                      margin: {
                        margin: "0px",
                        padding: "2px",
                      },
                    }}
                    checked={
                      arrayExport[rowPadre[campoExport]]?.some((item) => {
                        return item === row[campoExport];
                      })
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        if (arrayExport[rowPadre[campoExport]]) {
                          setarrayExport({
                            ...arrayExport,
                            [rowPadre[campoExport]]: [
                              ...arrayExport[rowPadre[campoExport]],
                              row[campoExport],
                            ],
                          });
                        } else {
                          setarrayExport({
                            ...arrayExport,
                            [rowPadre[campoExport]]: [row[campoExport]],
                          });
                        }
                      } else {
                        setarrayExport({
                          ...arrayExport,
                          [rowPadre[campoExport]]: arrayExport[
                            rowPadre[campoExport]
                          ].filter((item) => item !== row[campoExport]),
                        });
                      }
                    }}
                    color="secondary"
                  />
                }
              />
            </Box>
          </TableCell>
        ) : (
          <TableCell
            size="small"
            padding="checkbox"
            style={{
              borderRight: "2px solid #ccc",
              maxWidth: "1rem",
            }}
            className={claseCelda ? claseCelda : classes.celda}
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              <FormControlLabel
                sx={{
                  margin: {
                    margin: "0px",
                    padding: "2px",
                  },
                }}
                control={
                  <Checkbox
                    size="small"
                    sx={{
                      margin: {
                        margin: "0px",
                        padding: "2px",
                      },
                    }}
                    checked={arrayExport.some((item) => {
                      return item === row[campoExport];
                    })}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setarrayExport([...arrayExport, row[campoExport]]);
                      } else {
                        setarrayExport(
                          arrayExport.filter(
                            (item) => item !== row[campoExport]
                          )
                        );
                      }
                    }}
                    color="secondary"
                  />
                }
              />
            </Box>
          </TableCell>
        )
      ) : null}
      {agregarCelda ? (
        <TableCell
          className={claseCelda ? claseCelda : classes.celda}
        ></TableCell>
      ) : null}
      <TableCell
        className={claseCelda ? claseCelda : classes.celda}
        style={{ borderRight: "1px solid #ccc" }}
      >
        <Box display="flex" alignItems="center">
          {/* botones={[
                      {
                        tooltip: "Recuperar",
                        texto: "",
                        funcion: () => {
                          if (!tienePermiso("añadir")) {
                            return alertaPermiso("añadir");
                          }
                          funcRecuperar();
                        },
                        disabled: usuario.usuario !== rowHijo.antUser.split("/+/")[0],
                        Icon: Restore,
                        color: "success",
                        id: 3,
                        ocultar: !rowHijo.eliminado,
                        tipo: "icono",
                        variante: "contained",
                        size: "small",
                        sx: butonIconTransition,
                      }, */}
          {allBotones.length >= 3 && (
            <Botones
              botones={[
                {
                  tooltip: expanded ? "Contraer" : "Expandir",
                  texto: "",
                  funcion: () => {
                    setExpanded(!expanded);
                  },
                  disabled: false,
                  Icon: expanded ? MenuOpen : ReadMore,
                  color: "primary",
                  id: 3,
                  ocultar: false,
                  tipo: "icono",
                  variante: "contained",
                  size: "small",
                  sx: butonIconTransition,
                },
              ]}
            />
          )}
          {allBotones.length >= 3 ? (
            expanded ? (
              <Botones botones={allBotones} />
            ) : (
              <Botones botones={checkButtons} />
            )
          ) : (
            <Botones botones={allBotones} />
          )}
        </Box>
      </TableCell>
    </>
  );
};

export default withRouter(memo(ButtonsAction));
